<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Lead Details"
    description="Lead details information can be edited or updated at any time."
    primary-button-text="Save"
    secondary-button-text="Cancel"
    :disabled="loading"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <div v-if="lead.step?.name === 'Failed'" class="bg-secondary-05 p-4 text-sm text-additional-3-100">
      <div class="flex w-max items-center rounded-lg bg-additional-3-10 px-3 py-1.5 font-semibold">
        <UiIcon name="alert-circle" class="mr-1 size-4"></UiIcon>
        Failure
      </div>
      <p class="mt-3">
        Lead with failed phone number was created. The original number is
        <span class="font-semibold">{{ duplicateWrongPhone }}</span
        >.
      </p>
    </div>
    <form class="h-full" @submit.prevent>
      <h5 class="text-subhead-1 mb-4 text-black-60">Lead contacts</h5>
      <template v-if="isSelectPhoneTypeVisible">
        <p class="text-body mb-3">
          Outgoing calls goes to primary number. You can change the primary number and outgoing calls will be made to it
          with priority.
        </p>
        <UiInputRadio
          id="phone_via_id"
          v-model="localLead.phone_via_id"
          vertical
          input-class="mt-5"
          class="mb-8"
          :items="phoneSelectOptions"
        >
          <template #label="{ item }">
            <UiInputPhone
              v-model="localLead[item.field]"
              name="Phone"
              :label="item.label"
              :disabled="item.disabled"
              placeholder="Add a number"
              :error="useGetFieldErrors(v$, item.fieldErrors)"
            />
          </template>
        </UiInputRadio>
      </template>
      <UiInputPhone
        v-else
        v-model="localLead.lead_phone"
        name="Phone"
        label="Phone number"
        placeholder="Add a number"
        class="mb-8"
        :error="useGetFieldErrors(v$, ['lead_phone.phone', 'lead_phone.phone_country_id'])"
      />

      <h5 class="text-subhead-1 mb-4 text-black-60">Lead details</h5>
      <UiInputTextField
        v-model="localLead.name"
        label="Lead name *"
        name="Name"
        placeholder="Lead name"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['name'])"
      />
      <UiInputTextField
        v-model="localLead.email"
        label="Email"
        name="email"
        placeholder="Email"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['email'])"
      />
      <UiInputSelect
        v-model="localLead.country_id"
        :items="countries"
        label="Country *"
        name="country"
        placeholder="Choose country"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['country_id'])"
      />
      <UiInputSelect
        v-model="localLead.language_id"
        :items="languages"
        label="Language *"
        name="language"
        placeholder="Language"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['language_id'])"
      />

      <UiInputSelect
        v-model="localLead.timezone_id"
        :items="timezones"
        align-right
        label="Timezone"
        name="Timezone"
        placeholder="Select a timezone"
        class="mb-4"
      />

      <UiInputSelect
        v-model="localLead.lead_source_id"
        :items="sources"
        label="Source *"
        name="Source"
        placeholder="Type or select from the list"
        class="mb-4"
        add-new
        :error="useGetFieldErrors(v$, ['lead_source_id'])"
        @update:model-value="addCustomValue($event)"
        @updated:shallow-value="getSources($event)"
      />
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import type { InputItem, Lead, LibraryItem, Phone } from '@/types'
import { useUiStore } from '~/store/ui'
import { LEAD_PHONE_TYPES_CODES } from '~/constants'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)
const countries = ref<InputItem[]>([])
const languages = ref<InputItem[]>([])
const leadPhoneTypes = ref<LibraryItem[]>([])
const sources = ref<InputItem[]>([])
const timezones = ref<InputItem[]>([])

onNuxtReady(
  async () => await Promise.all([getCountries(), getLanguages(), getSources(), getTimezones(), getLeadPhoneTypes()])
)

const getCountries = async () => {
  countries.value = await useGetCountriesItems()
}
const getLanguages = async () => {
  languages.value = await useGetLanguageItems()
}

const { isCommunicationWayPartner, isCommunicationWayRepresentative } = useLeadStatus(toRef(props.lead))

const isSelectPhoneTypeVisible = computed(
  () => isCommunicationWayPartner.value || isCommunicationWayRepresentative.value
)

const getSources = async (query?: string) => {
  sources.value = await useGetLeadSourcesItems(query)
  const leadSource = sources.value.find((s) => s.value === props.lead.lead_source_id)
  if (!leadSource && !query) {
    sources.value.unshift({ text: props.lead.source?.name, value: props.lead.lead_source_id })
  }
}

const addCustomValue = async (value: string) => {
  if (!sources.value.length) {
    const { data } = await useAddLeadSource(value)
    sources.value.unshift({ text: data.name, value: data.id })
    localLead.value.lead_source_id = data.id
  }
}

const getTimezones = async () => {
  const data = await useTimezones()
  timezones.value = data.map((t) => ({ text: `${t.offset} ${t.name}`, value: t.id, ...t }))
}

type LocalLead = Partial<Lead> & {
  phone_via_id: LibraryItem['id']
  lead_phone: Phone
  representative_phone: Phone
  partner_phone: Phone
}

const localLead = ref<LocalLead>({
  ...props.lead,
  phone_country_id: props.lead.phone_country?.id,
  country_id: props.lead.country?.id,
  language_id: props.lead.language?.id,
  timezone_id: props.lead.timezone?.id,
  lead_source_id: props.lead.source?.id,
  phone_via_id: props.lead.phone_via?.id,
  partner_phone: {
    phone: props.lead.partner?.phone,
    phone_country_id: props.lead.partner?.phone_country?.id,
  },
  representative_phone: {
    phone: props.lead.representative?.phone,
    phone_country_id: props.lead.representative?.phone_country.id,
  },
  lead_phone:
    !useGetShowPhoneLead(props.lead) || useGetPhoneHide(props.lead)
      ? {
          phone: null,
          phone_country_id: null,
        }
      : {
          phone: Number(props.lead.phone),
          phone_country_id: props.lead.phone_country.id,
        },
})

const phoneSelectOptions = ref<(InputItem & { field: string; fieldErrors: string[]; label: string })[]>([])

const getLeadPhoneTypes = async () => {
  leadPhoneTypes.value = (await useLeadPhoneTypes()) || []

  const OPTION_MAP = {
    [LEAD_PHONE_TYPES_CODES.VIA_PARTNER]: {
      label: 'Partner phone number',
      disabled: true,
      field: 'partner_phone',
      fieldErrors: ['partner_phone.phone', 'partner_phone.phone_country_id'],
    },
    [LEAD_PHONE_TYPES_CODES.VIA_REPRESENTATIVE]: {
      label: 'Representative phone number',
      disabled: false,
      field: 'representative_phone',
      fieldErrors: ['representative_phone.phone', 'representative_phone.phone_country_id'],
    },
    [LEAD_PHONE_TYPES_CODES.PRIMARY]: {
      label: 'Lead phone number',
      disabled: false,
      field: 'lead_phone',
      fieldErrors: ['lead_phone.phone', 'lead_phone.phone_country_id'],
    },
  }

  const getFormattedOption = (way: LibraryItem) => {
    const option = OPTION_MAP[way.code as keyof typeof OPTION_MAP]

    if (!option) {
      throw new Error(`Unknown communication way: ${way.code}`)
    }

    return {
      ...way,
      value: way.id,
      text: way.name,
      ...option,
    }
  }

  const PHONE_SELECT_MAP = {
    [LEAD_PHONE_TYPES_CODES.PRIMARY]: true,
    [LEAD_PHONE_TYPES_CODES.VIA_PARTNER]: isCommunicationWayPartner.value,
    [LEAD_PHONE_TYPES_CODES.VIA_REPRESENTATIVE]: isCommunicationWayRepresentative.value,
  }

  phoneSelectOptions.value = leadPhoneTypes.value
    .filter(({ code }) => {
      return PHONE_SELECT_MAP[code as keyof typeof PHONE_SELECT_MAP]
    })
    .sort(({ code }) => (code === LEAD_PHONE_TYPES_CODES.PRIMARY ? 1 : -1))
    .map(getFormattedOption)
}

let duplicateWrongPhone: string | undefined | number | null
if (localLead.value.step?.name === 'Failed') {
  duplicateWrongPhone = localLead.value.phone
}

const validatePhone = (number?: number | null, countryId?: number | null) => {
  if (!number || !countryId) {
    return false
  }

  return useValidatePhone(+number!, countryId)
}

const PHONE_NUMBER_NOT_VALID = 'Phone number is not valid'

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  email: { email: helpers.withMessage('The email is not valid', email) },
  lead_phone: {
    phone: {
      validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
        validatePhone(localLead.value.lead_phone?.phone, localLead.value.lead_phone?.phone_country_id)
      ),
    },
    phone_country_id: {
      validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
        validatePhone(localLead.value.lead_phone?.phone, localLead.value.lead_phone?.phone_country_id)
      ),
    },
  },
  ...(isCommunicationWayRepresentative.value
    ? {
        representative_phone: {
          phone: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(
                localLead.value.representative_phone?.phone,
                localLead.value.representative_phone?.phone_country_id
              )
            ),
          },
          phone_country_id: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(
                localLead.value.representative_phone?.phone,
                localLead.value.representative_phone?.phone_country_id
              )
            ),
          },
        },
      }
    : {}),
  ...(isCommunicationWayPartner.value
    ? {
        partner_phone: {
          phone: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(localLead.value.partner_phone?.phone, localLead.value.partner_phone?.phone_country_id)
            ),
          },
          phone_country_id: {
            validatePhone: helpers.withMessage(PHONE_NUMBER_NOT_VALID, () =>
              validatePhone(localLead.value.partner_phone?.phone, localLead.value.partner_phone?.phone_country_id)
            ),
          },
        },
      }
    : {}),
  country_id: { required: helpers.withMessage('Country is required', required) },
  language_id: { required: helpers.withMessage('Language is required', required) },
  lead_source_id: { required: helpers.withMessage('Source is required', required) },
}))

const v$ = useVuelidate(rules, localLead)

const submit = async () => {
  const isValid = await v$.value.$validate()

  if (isValid) {
    loading.value = true

    const result = omitBy(localLead.value, (v) => v === null || v === undefined || v === 'hidden') as LocalLead

    const payload = {
      ...result,
      phone: result.lead_phone?.phone,
      phone_country_id: result.lead_phone?.phone_country_id,
      ...(result.representative?.phone
        ? {
            representative: {
              ...result.representative,
              phone: result.representative_phone.phone,
              phone_country_id: result.representative_phone.phone_country_id,
            },
          }
        : {}),
    } as Lead

    try {
      // TODO: fix type
      const leadUpdated = await useUpdateLead(props.lead.id, payload)
      uiStore.showSnackBanner('Lead updated successfully')
      emits('input', leadUpdated)
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
